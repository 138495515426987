import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoursesService } from '../../../services/courses.service';
import { Answer, Course, Restored_progress } from '../../../core/models/class/course';
import Backend from '../../../core/backend';
import { NbDialogContainerComponent, NbDialogService, NbStepperComponent } from '@nebular/theme';

@Component({
  selector: 'ngx-course-learning',
  templateUrl: 'course-learning.component.html',
  styleUrls: ['./course-learning.component.css'],
})
export class CourseLearningComponent implements OnInit {
  @ViewChild('stepper') stepper: NbStepperComponent;
  @ViewChild('lessonstep') lessonstep: NbStepperComponent;
  @ViewChild('dialog') dialog: TemplateRef<any>;

  course: Course = null;
  baseUrl = Backend.baseUrl;
  isLoading = true;
  linearMode = true;
  currentSectionIndex: number = 0;
  currentLessonIndex: number = 1;
  notCompleted: boolean = true;
  answers: Answer[] = [];
  wrong_question_ids: number[] = [];
  quiz_result: Number;
  attachementLink: string;
  constructor(
    private route: ActivatedRoute,
    protected _courseService: CoursesService,
    private dialogService: NbDialogService,
  ) {}
  id: string = this.route.snapshot.paramMap.get('id');
  Progress: Restored_progress;
  lessonIndex: number = 0;
  sectionIndex: number = 0;
  ngOnInit() {
    this._courseService.get(true).subscribe((data) => {
      this.course = data.find((e) => e.id === +this.id);
      this.isLoading = false;

      // Transform video URLs to embeddable format
      this.course.sections.forEach((section) => {
        section.lessons.forEach((lesson) => {
          lesson.video = this.transformYouTubeUrl(lesson.video);
        });
      });

      this._courseService.getProgress(this.id).subscribe((data) => {
        if (data.progress.last_section_id) {
          this.sectionIndex = this.course.sections.findIndex((e) => e.id === data.progress.last_section_id);
          if (this.sectionIndex !== -1) {
            this.lessonIndex = this.course?.sections[this.sectionIndex]?.lessons.findIndex(
              (e) => e.id === data.progress.last_lesson_id,
            );
          }
        }
      });
    });
  }


  transformYouTubeUrl(url: string): string {
    if (!url) return url;
    const videoIdMatch = url.match(
      /(?:\?v=|\/embed\/|\/watch\?v=|youtu\.be\/|\/v\/|\/e\/|watch\?v=|watch\?feature=player_embedded&v=)([^&?/]+)/,
    );
    return videoIdMatch ? `https://www.youtube.com/embed/${videoIdMatch[1]}` : url;
  }

  toggleLinearMode() {
    this.linearMode = !this.linearMode;
  }

  open() {
    this.dialogService.open(this.dialog, { context: 'this is some additional data passed to dialog' });
  }
  onNextClick() {
    const currentSection = this.course.sections[this.currentSectionIndex];
    const currentLesson = currentSection.lessons[this.currentLessonIndex - 1];
    if (this.currentLessonIndex < currentSection.lessons?.length) {
      this.currentLessonIndex++;
    } else if (this.currentSectionIndex < this.course.sections.length - 1) {
      this.currentSectionIndex++;

      this.currentLessonIndex = 1;

      this.stepper.next();
    } else {
      this.stepper.next();
      this.notCompleted = false;
      return;
    }

    this._courseService
      .saveProgress(this.id, currentSection.id, currentLesson.id)
      .subscribe((data) => console.log(data));
  }
  submit() {
    this.course.quizzes[0].questions.map((e) => {
      this.answers.push({ question_id: e.id, selected_answer: e.selectedAnswer });
    });
    this._courseService.quizzSubmit(this.id, this.course.quizzes[0].id, this.answers).subscribe((data) => {
      this.wrong_question_ids = data.wrong_question_ids;
      this.quiz_result = data.quiz_result;
      if (this.quiz_result === 100) {
        /*           window.open(Backend.baseUrl+data.certificate_url.replace('/backend',''),'_blank')
         */
        this.attachementLink = Backend.baseUrl + data.certificate_url.replace('/backend', '');
        this.open();
      }
    });
    this.answers = [];
  }

  isWrongQuestion(questionId: number): boolean {
    return this.wrong_question_ids.includes(questionId);
  }
}
