import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComplaintsService } from '../../../services/complaints.service';
import { CategoryService } from '../../../services/category.service';
import { ToastLuncherService } from '../../../core/services/toast-luncher.nb.service';
import { MunicipalityService } from '../../../services/municipality.service';
import { UpdateModeTypes } from '../../../core/models/types';
import { Category } from '../../../core/models/class/category';
import { Complaint } from '../../../core/models/class/complaint';
import { areValidImages } from '../../../core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { GenericCreateFeature } from '../../../core/models/class/GenericCreateFeature';
import * as ol from 'openlayers';

@Component({
  selector: 'ngx-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent extends GenericCreateFeature<Complaint> implements OnInit {
  modeInput = UpdateModeTypes.MODE_COMPLAINT;
  categories: Array<Category> = [];
  item: Complaint = new Complaint();
  googleMapLink: string = null;

  hasImage = false;
  isFileSizePassed = true;

  constructor(
    protected _toastLuncher: ToastLuncherService,
    protected router: Router,
    private _complaintsService: ComplaintsService,
    private categoryService: CategoryService,
    public imageCompress: NgxImageCompressService,
  ) {
    super(_complaintsService.prefix, _toastLuncher, _complaintsService, router);

    this.getCategoriesAndReclamations();
  }

  ngOnInit() {
    this.getCurrentPos();
  }

  onImageLoad($event, compressedImage, ...args) {
    this.item.image = compressedImage; // Ensure Base64 format if required
  }

  onChangeImagefile(fileInput, ...args) {
    const fileError = areValidImages(fileInput.target.files);
    if (fileError.error) throw new Error(fileError.errorMessage);

    this.hasImage = false;
    const reader = new FileReader();
    let src;
    reader.onload = (e) => {
      src = reader.result;
      this.imageCompress
        .compressFile(src, 75, 75) // Compress to 75% ratio and quality
        .then((compressedImage) => {
          this.onImageLoad(e, compressedImage, ...args);
        });
    };

    if (fileInput.target.files[0].size < 10 * 1024 * 1024) {
      this.isFileSizePassed = true;
      reader.readAsDataURL(fileInput.target.files[0]);
    } else {
      this.isFileSizePassed = false;
      throw new Error('الرجاء إدخال صورة لا تتجاوز 10MB');
    }
  }

  getCategoriesAndReclamations() {
    this.categoryService.get().subscribe((data) => {
      this.categories = data;
      if (this.categories.length > 0) {
        this.item.category = this.categories[0].name ?? null; // Use nullish coalescing to avoid null
      }
    });
  }

  onCategoryChanged() {
    this.item.sub_category = this.item.category?.length ? this.item.category : null; // Ensure string assignment
  }

  submit() {
    console.log('submit', this.item);

    if (!this.validatePayload()) {
      this._toastLuncher.alert('يرجى ملء جميع الحقول المطلوبة.');
      return;
    }

    this.item.category = this.getCategoryName(this.item.category);

    if (!this.item.category) {
      this._toastLuncher.alert('يرجى اختيار الفئة.');
      return;
    }

    this.item.is_public = true;
    if (this.item.latitude) {
      this.item.latitude = parseFloat(this.item.latitude.toFixed(6));
    }
    if (this.item.longitude) {
      this.item.longitude = parseFloat(this.item.longitude.toFixed(7));
    }
    this.item.created_by_id = 0;

    console.log('Payload being sent:', this.item);

    this._complaintsService.post(this.item).subscribe(
      (response) => {
        this._toastLuncher.success('تم إنشاء المشكل بنجاح');
        this.router.navigate([`/pages${this.featureName}/liste`]);
      },
      (error) => {
        console.error('Submission error:', error);
        this._toastLuncher.alert('حدث خطأ أثناء إنشاء المشكل.');
      },
    );
  }
  private getCategoryName(category: any): string | null {
    return category && typeof category === 'object' ? category.name : category || null;
  }
  onLocationSelect(event) {
    this.item.latitude = ol.proj.transform(event.coordinate, 'EPSG:3857', 'EPSG:4326')[1];
    this.item.longitude = ol.proj.transform(event.coordinate, 'EPSG:3857', 'EPSG:4326')[0];
  }

  getCurrentPos() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.item.latitude = +pos.coords.latitude;
          this.item.longitude = +pos.coords.longitude;
        },
        () => {},
      );
    }
  }

  import() {
    document.getElementById('file_to_upload').click();
  }

  validatePayload() {
    console.log(this.item);
    const requiredFields = ['category', 'sub_category', 'address', 'problem'];
    for (const field of requiredFields) {
      if (!this.item[field]) {
        return false;
      }
    }
    return true;
  }
}
